/*
import React from "react"
import Lottie from "lottie-react"

import {placeholderReel} from "../assets/index"


const PlaceHolder = () => {

const animbox = document.getElementById('animbox');
const checkem = () =>{
  console.log('fuck');
}

  return (
    
    <Lottie
    onClick={checkem}
    className="reel"
        animationData={placeholderReel}
        loop={true}
      />

  )
}
*/




import React from 'react';
import { placeholderReel } from '../assets'; 
import Lottie from "lottie-react"

function PlaceHolder() {
  
const animbox = document.getElementById('animbox');
const checkem =()=>{console.log('beans')}
  return (
    <>
    

    <div>
    <Lottie
    onClick={checkem}
    className="reel"
        animationData={placeholderReel}
        loop={true}
      />
        
    
    </div>
    </> );
}
export default PlaceHolder;