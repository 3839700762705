import React from "react"
import Lottie from "lottie-react"
import "./styles.css"
import {
  lander,
  girlvideo,
  squarechange,
  shifty,
} from "../assets/index"

import CaseStudy from "../components/CaseStudy"
import PlaceHolder from "../components/placeholder"

export default () => (
  <>
    <style>
      @import
      url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
    </style>

    <div className="splash">
      <p className="toptext">headspace.studio</p>
      <div className="splashcontainer">
        <h1 className="splashtext">
          We make videos for <span className="humans">humans</span> everywhere.
        </h1>
        <br />
        <a href="#whoweare" className="splashbutton">
          What we do
        </a>
      </div>

      <Lottie className="lander" animationData={lander} loop={true} />
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 140" className="wave">
      <path
        fill="#3098f3"
        fill-opacity="1"
        d="M0,96L60,106.7C120,117,240,139,360,133.3C480,128,600,96,720,80C840,64,960,64,1080,69.3C1200,75,1320,85,1380,90.7L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
      ></path>
    </svg>

    <section className="whoweare">
      <div className="whowearehalf">
        <Lottie
          animationData={squarechange}
          loop={true}
          className="squarechange"
        />
      </div>

      <div className="whowearehalf whowearetext">
        <h2>here at headspace.studio</h2>
        <div className="mediumismessage">
          <h2>
            we believe
            <span className="humans"> the medium is the message</span>
          </h2>
        </div>
      </div>

      <div className="whowearehalf whowearemobiletext">
        <h2>
          here at headspace.studio we believe
          <span className="humans"> the medium is the message</span>
        </h2>
      </div>
    </section>
    <section className="whoweare2">
      
    <Lottie className="lottieright" animationData={shifty} loop={true} />
      <div className="whoweare2text">
        <h1>so we do</h1>
    
            <span >
              <h1 className="whoweare2zindex">motion graphics & video editing</h1>
              <svg
            viewBox="0 0 100 10"
            xmlns="http://www.w3.org/2000/svg"
            className="underliner">
            <line x1="0" y1="2" x2="10" y2="2" />
          </svg>
            </span>
        
         

          <h2>for people and companies that care</h2>
    

        <button className="arrowbutton">{`>`}</button>
      </div>
    </section>

    <section className="whatwedo" id="whatwedo">
      <h2 className="sectiontitle">Check out our 2022 Motion Design Reel</h2>
      <div id="animbox">
        <iframe
          className="reel"
          src="https://player.vimeo.com/video/718096298?h=87a6c7412c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="445px"
          height="auto"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="2022 Reel"
        ></iframe>
      </div>
      <div className="whatwedotext">
        <p>
          Create a visual identity for your company, and an overall brand
          experience between you and your customers.
        </p>
      </div>
    </section>
    <footer>
      <h3>We want to hear about what you're building</h3>
    </footer>

  </>
)
